import React from "react"
import styled from "styled-components"
import Title from "./title"
import Overline from "./overline"
import Section from "./section"
import { vw } from "../../utilities/pxtovw"
import Text from "./text"
import Pattern from "../shared/pattern"
import Planner from "./planner"
import AccordionItem from "./accordion-item"
import { rem } from "../../utilities/pxtorem"
import GetStartedBtn from "./get-started-btn"
import { StaticImage } from "gatsby-plugin-image"

const Copy = styled.div`
  position: relative;
  padding-left: ${vw(108)};
  padding-bottom: ${vw(72)};

  &.faqs {
    padding-top: ${vw(72)};
    padding-bottom: ${vw(48)};
    h2 {
      padding-bottom: 0;
    }
  }

  > section {
    width: ${vw(650)};
  }

  .xn-pattern {
    position: absolute;
    right: 0;
    top: ${vw(46)};
    height: ${vw(42)};
    width: ${vw(197)};
    right: 0;
  }

  h2 {
    width: 75%;
  }

  @media screen and (max-width: 766px) {
    padding: 2rem;

    > section {
      width: 100%;
    }

    h2 {
      width: 100%;
    }
  }
`

const Mockup = styled.div`
  position: absolute;
  width: 13vw;
  height: 21vw;
  right: 8.4375vw;
  top: -6vw;

  @media screen and (max-width: 766px) {
    display: none;
  }
`

const Faqs = styled.ul`
  padding: ${vw(0)} ${vw(3.25 * 108)} 0;

  @media screen and (max-width: 766px) {
    padding: 2rem;
  }
`

const Disclaimer = styled.div`
  padding: 0 ${vw(108)};
  padding-top: 2.125rem;
  display: flex;
  justify-content: space-between;

  .copy {
    width: 65%;
    opacity: 0.75;
    font-size: ${rem(12)};

    a {
      text-decoration: underline;
      color: ${props => props.theme.mint};
    }
  }

  @media screen and (max-width: 766px) {
    display: block;
    .copy {
      width: 100%;
      padding-bottom: 3rem;
    }
  }
`

const Splash = () => {
  return (
    <Section className="clear-bottom">
      <Copy>
        <section>
          <Overline>Investment</Overline>
          <Title>
            <h2>How XENO invests and grows your group savings</h2>
          </Title>
          <Text>
            <p>
              XENO ensures that the money contributed by group members is always
              invested and the investment returns help you reach your collective
              targets faster.
            </p>
          </Text>
        </section>
        <Pattern size="4" type="dots" />
        <Mockup>
          <StaticImage
            src="https://res.cloudinary.com/xeno-technologies/image/upload/c_fit,h_1320,q_auto:good,w_640/v1624431662/Mockups/xeno-plan_qobzuy_ccwe3w.png"
            alt="XENO investment plan on mobile phone"
            objectFit="contain"
          />
        </Mockup>
      </Copy>
      <Planner />
      <Disclaimer>
        <div className="copy">
          <p>
            *
            <a
              href="https://myxeno.com/create-account?goal=emergencies&src=ppc"
              target="_blank"
              rel="noreferrer"
            >
              Create a profile
            </a>{" "}
            to run more scenarios. These are scenarios not predictions. The
            value of your investments may go up as well as down. You may get
            back less than the amount you invested. Past performance is no
            guarantee of future performance. The purpose of the simulation is
            merely to illustrate the distribution of possible investment
            outcomes within a reasonable level of confidence.
          </p>
        </div>
        <GetStartedBtn title="Get a Free Plan" section="investment" />
      </Disclaimer>
      <Copy className="faqs">
        <section>
          <Overline>Support</Overline>
          <Title>
            <h2>Frequently Answered Questions</h2>
          </Title>
        </section>
      </Copy>
      <Faqs>
        <AccordionItem title="How do I access my account through the web?">
          <ol>
            <li>
              The administrator of your group sets up the group account and
              uploads all the members by sending them an invitation link to your
              emails to join the group-linked account. The member then visits
              www.myxeno.com and clicks on the login button.
            </li>
            <li>
              Select forgot password and log in using either your work mail or
              the email provided to the group administrator.
            </li>
            <li>
              The password should contain at least 1 upper case, number, special
              charter, and lower case the password should be a minimum of 8
              charters.
            </li>
            <li>
              A verification code is sent to your email to confirm the change of
              password.
            </li>
          </ol>
          <p>
            Log in to complete your Account setup, and provide the following
            requirements:
          </p>
          <ol>
            <li>Personal details (Names, Date of birth, nationality)</li>
            <li>
              A government-issued ID (National ID, Passport, or Driving Permit).
            </li>
            <li>Passport-size photo (full-faced selfie)</li>
            <li>Physical address (Road/Street, County, District, Region)</li>
            <li>Source of Income</li>
          </ol>
        </AccordionItem>
        <AccordionItem title="How can I access my group account via the MTN USSD?">
          <p>
            Please follow the steps below to link your MTN Number to your XENO
            online Account;
          </p>
          <ol>
            <li>Go to your www.myxeno.com and Login</li>
            <li>Select Settings on the drop-down menu</li>
            <li>Select Security</li>
            <li>Fill in the desired MTN number registered in your names</li>
            <li>Select VERIFY and Wait for a confirmation</li>
          </ol>
        </AccordionItem>
        <AccordionItem title="How can I track my performance using MTN USSD?">
          <ol>
            <li>Dial *165*5*7#</li>
            <li>Select My Accounts: option 2</li>
            <li>Select Scheme: Option 2</li>
            <li>Select Account Summary to view the performance.</li>
          </ol>
        </AccordionItem>
        <AccordionItem title="Will all the members be able to view their contributions">
          <p>
            Yes, all members will be linked to the group account and are able to
            monitor the performance and track the progress of their investment
            through their member dashboards.
          </p>
        </AccordionItem>
        <AccordionItem title="Who is allowed to withdraw the funds?">
          <p>
            All members are allowed to request a withdrawal through their admin
            who issues the instructions to XENO via email.
          </p>
        </AccordionItem>
        <AccordionItem title="Can I be able to fund my account individually?">
          <p>
            Yes, you will be able to make your individual contributions through
            mobile money and bank using the XENO website, app and MTN USSD
          </p>
        </AccordionItem>
        <AccordionItem title="Are we supposed to fund on a monthly basis?">
          <p>
            Making periodic deposits depends on the group’s objective set by the
            members.
          </p>
        </AccordionItem>
        <AccordionItem title="How many members can be in the group?">
          <p>You can have as many members as you would like.</p>
        </AccordionItem>
        <AccordionItem title="Can one operate an individual account under their group account?">
          <p>
            Yes, you can by logging into your account, selecting add account and
            creating your personal account. This added personal account will not
            be visible to the club admin and it's treated as a private account
            managed and accessed only by the member that decides to set up other
            financial goals separate from the group investment.
          </p>
        </AccordionItem>
        <AccordionItem title="We have a member out of Uganda. Can they be part of the group and how will they fund their account?">
          <p>
            Yes, they can join the group and can send funds directly to our
            custodian account held with Stanbic and they are given an option of
            investing in either our shillings account or USD account.
          </p>
        </AccordionItem>
        <AccordionItem title="What happens if a member exits the club?">
          <p>
            The member can formally request to withdraw their portion of the
            funds and will be paid out via mobile money or Bank through the
            admin.
          </p>
        </AccordionItem>
        <AccordionItem title="What happens in case we want to exit the fund?">
          <p>
            The admin sends a request to Uganda@myxeno.com requesting for all
            their funds to be transferred to the bank account, and this can be
            done either partially or all funds invested.
          </p>
        </AccordionItem>
      </Faqs>
    </Section>
  )
}
export default Splash
