export const sitemap = [
  [
    {
      label: "Company",
      slug: "https://invest.myxeno.com/company",
    },
    {
      label: "XENO & MTN MoMo",
      slug: "https://myxeno.com/mtn-momo",
    },
    {
      label: "Learn",
      slug: "https://learn.myxeno.com/",
    },
    {
      label: "Support",
      slug: "https://learn.myxeno.com",
    },
  ],
  [
    {
      label: "Terms of use",
      slug: "https://invest.myxeno.com/terms-of-use",
    },
    {
      label: "Privacy Policy",
      slug: "https://invest.myxeno.com/privacy-policy",
    },
    {
      label: "Client Agreement",
      slug: "https://myxeno.com/client-agreement",
    },
  ],
]
